<template>
  <div>
    <div class="uk-text-large">
      Precios <span v-if="colors || faces || printTechnique">- </span>
      <span v-if="printTechnique"
        ><span v-if="printTechnique === 'serigraphy'">Serigrafía</span>
        <span v-if="printTechnique === 'pad-printing'">Tampografía</span>
        <span v-if="printTechnique === 'sublimation'">Sublimación</span></span
      >
      <span v-if="faces"> {{ faces }} cara{{ faces !== 1 ? "s" : "" }}</span
      ><span v-if="faces && colors">,</span>
      <span v-if="colors"
        >{{ colors }} color{{ colors !== 1 ? "es" : "" }}</span
      >
    </div>
    <ul class="uk-child-width-expand uk-margin-remove" uk-tab id="price-table">
      <li
        v-for="innerShippingMethod in Object.keys(priceTables)"
        :key="'priceTable-title-' + innerShippingMethod"
        class="uk-padding-remove"
      >
        <a
          href="#"
          class="uk-border-rounded"
          :class="{ 'uk-active': innerShippingMethod === shippingMethod }"
          @click="setShippingMethod(innerShippingMethod)"
          >{{
            {
              local: "1 semana",
              courier: "2 a 3 semanas",
              airway: "4 a 5 semanas",
              ship: "6 a 7 semanas",
            }[innerShippingMethod]
          }}</a
        >
      </li>
    </ul>

    <ul class="uk-switcher">
      <li
        v-for="shippingMethod in Object.keys(priceTables)"
        :key="'priceTable-' + shippingMethod"
      >
        <table
          class="uk-table uk-table-striped uk-table-hover uk-margin-remove"
        >
          <thead>
            <th width="30%" class="uk-text-center">Cantidad</th>
            <th width="30%" class="uk-text-center">Precio unitario</th>
            <th width="30%" class="uk-text-center">Precio total</th>
          </thead>
          <tbody>
            <tr
              v-for="quantity in Object.keys(priceTables[shippingMethod])"
              :key="quantity"
            >
              <td class="uk-text-center">{{ formatThousands(quantity) }}</td>
              <td class="uk-text-center">
                {{ toCurrency(priceTables[shippingMethod][quantity]) }}
              </td>
              <td class="uk-text-center">
                {{
                  toCurrency(priceTables[shippingMethod][quantity] * quantity)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </li>
    </ul>
  </div>
</template>

<script>
import UIkit from "uikit";
export default {
  name: "PriceTable",

  props: ["priceTables", "faces", "colors", "printTechnique", "shippingMethod"],

  methods: {
    setShippingMethod(shippingMethod) {
      this.$root.$emit("set-shipping-method", shippingMethod);
    },
  },

  mounted() {
    if (this.shippingMethod) {
      UIkit.tab("#price-table").show(
        Object.keys(this.priceTables).indexOf(this.shippingMethod)
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.uk-tab > .uk-active > a {
  border-bottom: solid 2px;
}

.uk-tab > .uk-active > a {
  background-color: $global-primary-background;
  color: white;
  border: none;
}
</style>
